
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { fixPrice, parseToNumber } from '@/utils/general'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'
  import { PaymentOrder } from '@/entities/finance'
  import { PurchaseOrder } from '@/entities/purchase'

interface Icon {
  icon: string
  color: string
}

@Component({
  methods: { fixPrice },
})
  export default class ExpandablePaymentOrder extends Expandable {
  @Prop({ type: String }) autoLabel!: string
  @Prop({ type: String }) penaltiesLabel!: string
  @Prop({ type: String }) reservationOfTitleLabel!: string
  @Prop({ type: String, default: 'Retención' }) retentionLabel!: string
  @Prop({
    type: Object,
    default: () => {
      return { icon: '', color: '' }
    },
  }) approvedIcon!: Icon

  @Prop({
    type: Object,
    default: () => {
      return { icon: '', color: '' }
    },
  }) missingIcon!: Icon

  paymentOrder: PaymentOrder | null = null
  panels = []
  price = 0
  auto = []
  trafficTickets = []
  debs = []
  retention = []

  async mounted () {
    const { id, payments } = this.value as PurchaseOrder

    if (!this.value.paymentOrder?.payments?.length && id) {
      const paymentOrder = await this.fetchData({
        query: { name: 'find', model: 'PaymentOrder' },
        filter: { id_process_record: { _eq: id } },
        force: true,
      })
      this.paymentOrder = paymentOrder[0]
    } else {
      this.paymentOrder = this.value.paymentOrder
    }

    this.auto = this.paymentOrder?.payments?.filter(pay => !payments?.map(pay => pay?.id)
      ?.includes(pay?.id))?.filter(payment => payment.type === 'auto_payment') || []
    this.trafficTickets = this.paymentOrder?.payments?.filter(pay => !payments?.map(pay => pay?.id)
      ?.includes(pay?.id))?.filter(payment => payment.type === 'traffic_ticket_payment') || []
    this.debs = this.paymentOrder?.payments?.filter(pay => !payments?.map(pay => pay?.id)
      ?.includes(pay?.id))?.filter(payment => payment.type === 'debt_payment') || []
    this.retention = this.paymentOrder?.payments?.filter(pay => !payments?.map(pay => pay?.id)
      ?.includes(pay?.id))?.filter(payment => payment.type === 'retention') || []
    this.panels = this.opened ? [0, 1, 2, 3, 4] : []
  }

  get iconTitle (): Icon {
    const { approvedIcon, missingIcon } = this

    return approvedIcon || missingIcon
  }

  get penalties () {
    return false
  }

  get reservationOfTitle () {
    return Boolean(this.debs.length)
  }

  @Watch('opened', { immediate: true }) onChange (val) {
    this.panels = val ? [0, 1, 2, 3] : []
  }

  get expenses () {
    const { paymentOrder, price, value } = this

    if (!paymentOrder) {
      return {
        total: price || value.agreedAmount,
        available: 0,
        expenses: 0,
      }
    }
    const { items } = paymentOrder
    let totalExpense = 0

    items?.forEach(item => {
      totalExpense += item.expenses
    })

    const total = parseToNumber(price) || value?.agreedAmount
    const available = total - totalExpense
    const expenses = totalExpense

    return {
      total,
      available,
      expenses,
    }
  }

  @Watch('value', { deep: true, immediate: true }) onValueChange (val) {
    const { payments } = this.value as PurchaseOrder
    this.paymentOrder = val.paymentOrder
    this.auto = this.paymentOrder?.payments?.filter(pay => !payments?.map(pay => pay?.id)
      ?.includes(pay?.id))?.filter(payment => payment.type === 'auto_payment') || []
    this.trafficTickets = this.paymentOrder?.payments?.filter(pay => !payments?.map(pay => pay?.id)
      ?.includes(pay?.id))?.filter(payment => payment.type === 'traffic_ticket_payment') || []
    this.debs = this.paymentOrder?.payments?.filter(pay => !payments?.map(pay => pay?.id)
      ?.includes(pay?.id))?.filter(payment => payment.type === 'debt_payment') || []
    this.retention = this.paymentOrder?.payments?.filter(pay => !payments?.map(pay => pay?.id)
      ?.includes(pay?.id))?.filter(payment => payment.type === 'retention') || []
  }
  }
